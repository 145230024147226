const initialState = {
  currentProduct: "ru",
  currentCurrency: "RUB",
  min: 30000,
  max: 20000000,
  step: 10000,
  currentVal: 30000,
  currentActiveBond: "RU000A107C34",
  lastActiveBonds: {
    ru: "RU000A107C34",
  },
  products: {
    ru: {
      RU000A107C34: {
        rate: 18,
        cost: 1000,
        date: "2026-11-21",
        term: "07.02.2023 - 21.11.2026",
        termM: 36,
        sign: "₽",
        currency: "RUB",
        name: {
          ru: "АйДи Коллект-06",
          kz: "АйДи Коллект-06",
          en: "ID Collect-06",
        },
      },
      RU000A1071Z8: {
        rate: 16,
        cost: 1000,
        date: "2026-10-03",
        term: "19.10.2023- 03.10.2026",
        termM: 36,
        sign: "₽",
        currency: "RUB",
        name: {
          ru: "АйДи Коллект-05",
          kz: "АйДи Коллект-05",
          en: "ID Collect-05",
        },
      },
      RU000A106XT3: {
        rate: 16.5,
        cost: 1000,
        date: "2026-09-09",
        term: "26.09.2023- 09.09.2026",
        termM: 36,
        sign: "₽",
        currency: "RUB",
        name: {
          ru: "АйДи Коллект-04",
          kz: "АйДи Коллект-04",
          en: "ID Collect-04",
        },
      },
      RU000A1065M8: {
        rate: 15,
        cost: 1000,
        date: "2026-04-10",
        term: "16.05.2023 - 10.04.2026",
        termM: 36,
        sign: "₽",
        currency: "RUB",
        name: {
          ru: "АйДи Коллект-03",
          kz: "АйДи Коллект-03",
          en: "ID Collect-03",
        },
      },
      RU000A104JV3: {
        rate: 16.5,
        cost: 1000,
        date: "2025-01-31",
        term: "06.02.2022 – 31.01.2025",
        termM: 36,
        sign: "₽",
        currency: "RUB",
        name: {
          ru: "АйДи Коллект-02",
          kz: "АйДи Коллект-02",
          en: "ID Collect-02",
        },
      },
      RU000A103PS8: {
        rate: 12.25,
        cost: 1000,
        date: "2024-08-31",
        term: "16.09.2021 – 31.08.2024",
        termM: 32,
        sign: "₽",
        currency: "RUB",
        name: {
          ru: "МФК Мани Мен-02",
          kz: "МФК Мани Мен-02",
          en: "MoneyMan MFC-02",
        },
      },
      RU000A103HG0: {
        rate: 12,
        cost: 1000,
        date: "2024-07-25",
        term: "10.08.2021 – 25.07.2024",
        termM: 36,
        sign: "₽",
        currency: "RUB",
        name: {
          ru: "АйДи Коллект-01",
          kz: "АйДи Коллект-01",
          en: "ID Collect-01",
        },
      },
      name: "₽",
    },
  },
};

export const calcBondsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "INIT_BONDS_CALC": {
      return {
        ...state,
        currentTerm: 0,
        currentPercent: 0,
      };
    }
    case "UPDATE_BONDS_CALC_PRODUCT": {
      const currentProduct = action.data;
      const currentActiveBond = state.lastActiveBonds[currentProduct];
      const currentCurrency =
        state.products[currentProduct][currentActiveBond].currency;

      return {
        ...state,
        currentProduct: currentProduct,
        currentCurrency: currentCurrency,
        currentActiveBond: currentActiveBond,
      };
    }
    case "UPDATE_BONDS_CALC_TERM": {
      return {
        ...state,
        currentTerm: action.data,
        currentPercent: action.data,
      };
    }
    case "UPDATE_BONDS_CALC_CURRENCY_VAL": {
      return {
        ...state,
        currentVal: action.data,
      };
    }
    case "UPDATE_BONDS_CALC_ACTIVE_BONDS": {
      const currentProduct = state.currentProduct;

      return {
        ...state,
        lastActiveBonds: {
          ...state.lastActiveBonds,
          [currentProduct]: action.data,
        },
      };
    }
    case "UPDATE_BONDS_CALC_CURRENT_ACTIVE_BOND": {
      const currentProduct = state.currentProduct;
      const currentActiveBond = action.data;
      const currentCurrency =
        state.products[currentProduct][currentActiveBond].currency;

      return {
        ...state,
        currentCurrency: currentCurrency,
        currentActiveBond: currentActiveBond,
      };
    }
    default: {
      return state;
    }
  }
};
