const initialState = {
    pages: {
        isPageMain: null,
        isPageError: null,
    },
    isLightHeader: false,
    isNavSticky: false,
    lang: {
        current: null,
        all: null,
    },
    langList: {
        listOpen: false,
    },
    sidebar: {
        navOpen: false,
        subnavOpen: false,
    },
    modal: {
        open: false,
        type: null,
        productType: null,
    },

    subscribeModal: {
        isOpen: false,
        type: null,
    },

    footer: {
        subnavOpen: false,
    },
    ip: null,
    oldUserData: {},
    managerData: false,
};

export const baseReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_LANG':
            return {
                ...state,
                lang: {
                    ...state.lang,
                    current: action.data,
                },
            };
        case 'UPDATE_LANG_LIST_STATE':
            return {
                ...state,
                langList: {
                    listOpen: !state.langList.listOpen,
                },
            };
        case 'UPDATE_LANGS':
            return {
                ...state,
                lang: {
                    ...state.lang,
                    all: action.data,
                },
            };
        case 'UPDATE_PAGE_TYPE':
            return {
                ...state,
                pages: {
                    ...state.pages,
                    isPageMain: action.data.isPageMain,
                    isPageError: action.data.isPageError,
                },
            };
        case 'UPDATE_HEADER_STYLE':
            return {
                ...state,
                isLightHeader: action.data,
            };
        case 'UPDATE_SIDEBAR_NAV_STATE':
            return {
                ...state,
                sidebar: {
                    ...state.sidebar,
                    navOpen: !state.sidebar.navOpen,
                    subnavOpen: false,
                },
            };
        case 'UPDATE_SIDEBAR_SUBNAV_STATE':
            return {
                ...state,
                sidebar: {
                    ...state.sidebar,
                    subnavOpen: action.data,
                },
            };
        case 'UPDATE_FOOTER_SUBNAV_STATE':
            return {
                ...state,
                footer: {
                    subnavOpen: action.data,
                },
            };
        case 'UPDATE_MODAL_STATE':
            const open = state.modal.open;
            const {
                data: { type, productType, city, email, dataVacancy, videoLink },
            } = action;

            return {
                ...state,
                modal: {
                    open: !open,
                    type: !open ? type : null,
                    productType: !open ? productType : null,
                    modalData: !open ? city : null,
                    modalDataEmail: !open ? email : null,
                    dataVacancy: !open ? dataVacancy : null,
                    videoLink: !open ? videoLink : null,
                },
            };

        case 'UPDATE_SUBSCRIBE_MODAL_STATE':
            return {
                ...state,
                subscribeModal: {
                    isOpen: action.data.open,
                    type: action.data.type,
                },
            };

        case 'SET_NAV_STICKY':
            return {
                ...state,
                isNavSticky: true,
            };
        case 'SET_NAV_UNSTICKY':
            return {
                ...state,
                isNavSticky: false,
            };
        case 'SET_IP':
            return {
                ...state,
                ip: action.data,
            };
        case 'SET_OLD_USER_DATA':
            return {
                ...state,
                oldUserData: action.data.CQdata,
            };
        case 'SET_MANAGER_DATA':
            return {
                ...state,
                managerData: action.data,
            };
        default:
            return state;
    }
};
