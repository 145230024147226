const initialState = {
  currentProduct: "mm",
  currentCurrency: null,
  currentPercent: null,
  currentTerm: null,
  rateRise: false,
  products: {
    mm: {
      name: "Moneyman",
      currentCurrency: "rub",
      currentTerm: 0,
      currentPercent: 0,
      currency: {
        rub: {
          min: [1500000, 1500000],
          max: 20000000,
          step: 100000,
          currentVal: 1500000,
          percents: [11, 13],
          terms: [6, 12],
          name: "₽",
        },
      },
    },
    id_collect: {
      name: "ID Collect",
      currentCurrency: "rub",
      currentTerm: 0,
      currentPercent: 0,
      currency: {
        rub: {
          min: [300000, 300000, 300000, 300000],
          max: 20000000,
          step: 100000,
          currentVal: 300000,
          percents: [13, 15, 16, 15.5],
          terms: [6, 12, 24, 36],
          name: "₽",
        },
      },
    },
    idf: {
      name: "OMF",
      currentCurrency: "usd",
      currentTerm: 0,
      currentPercent: 0,
      currency: {
        usd: {
          min: [30000, 30000, 30000, 30000],
          max: 2000000,
          step: 10000,
          currentVal: 30000,
          percents: [7, 9, 10, 11],
          terms: [6, 12, 24, 36],
          name: "$",
        },
        eur: {
          min: [30000, 30000, 30000, 30000],
          max: 1500000,
          step: 10000,
          currentVal: 30000,
          percents: [6, 8, 9, 10],
          terms: [6, 12, 24, 36],
          name: "€",
        },
      },
    },
  },
};

export const calcVacancyReducer = (state = initialState, action) => {
  switch (action.type) {
    case "INIT_VACANCY_CALC": {
      const productCurrentCurrency =
        state.products[state.currentProduct].currentCurrency;
      const productCurrentPercent =
        state.products[state.currentProduct].currentPercent;
      const productCurrentTerm =
        state.products[state.currentProduct].currentTerm;
      return {
        ...state,
        currentCurrency: productCurrentCurrency,
        currentPercent: productCurrentPercent,
        currentTerm: productCurrentTerm,
      };
    }
    case "UPDATE_VACANCY_CALC_PRODUCT": {
      const product = action.data;
      const productCurrentCurrency = state.products[product].currentCurrency;
      const productCurrentPercent = state.products[product].currentPercent;
      const productCurrentTerm = state.products[product].currentTerm;
      return {
        ...state,
        currentProduct: product,
        currentCurrency: productCurrentCurrency,
        currentPercent: productCurrentPercent,
        currentTerm: productCurrentTerm,
      };
    }
    case "UPDATE_VACANCY_CALC_CURRENCY": {
      const product = state.currentProduct;
      const currency = action.data;
      const productCurrentPercent = state.products[product].currentPercent;
      const productCurrentTerm = state.products[product].currentTerm;
      return {
        ...state,
        currentCurrency: currency,
        currentPercent: productCurrentPercent,
        currentTerm: productCurrentTerm,
        products: {
          ...state.products,
          [product]: {
            ...state.products[product],
            currentCurrency: currency,
          },
        },
      };
    }
    case "UPDATE_VACANCY_CALC_TERM": {
      const product = state.currentProduct;
      const term = action.data;
      const percent = action.data;
      return {
        ...state,
        currentTerm: term,
        currentPercent: percent,
        products: {
          ...state.products,
          [product]: {
            ...state.products[product],
            currentPercent: percent,
            currentTerm: term,
          },
        },
      };
    }
    case "UPDATE_VACANCY_CALC_CURRENCY_VAL": {
      const product = state.currentProduct;
      const currency = state.currentCurrency;
      const productCurrencyVal = action.data;
      return {
        ...state,
        products: {
          ...state.products,
          [product]: {
            ...state.products[product],
            currency: {
              ...state.products[product].currency,
              [currency]: {
                ...state.products[product].currency[currency],
                currentVal: productCurrencyVal,
              },
            },
          },
        },
      };
    }
    case "UPDATE_VACANCY_CALC_RATE_RISE":
      return {
        ...state,
        rateRise: action.data,
      };
    default: {
      return state;
    }
  }
};
