import { createStore, combineReducers } from "redux";

import { baseReducer } from "./reducers/base";
import { calcLoansReducer } from "./reducers/calc_loans";
import { calcBondsReducer } from "./reducers/calc_bonds";
import { calcVacancyReducer } from "./reducers/calc_vacancy";

const rootReducer = combineReducers({
  base: baseReducer,
  calcLoans: calcLoansReducer,
  calcBonds: calcBondsReducer,
  calcVacancy: calcVacancyReducer,
});

const composeEnhancers =
  typeof window !== "undefined" &&
  window.__REDUX_DEVTOOLS_EXTENSION__ &&
  window.__REDUX_DEVTOOLS_EXTENSION__();

export default preloadedState => {
  return process.env.NODE_ENV === "development"
    ? createStore(rootReducer, preloadedState, composeEnhancers)
    : createStore(rootReducer, preloadedState);
};
